.game-overview-container {
  display: flex;
  margin: 30px 0;
  align-items: center;
  flex-direction: column;

  .game-overview-table {
    width: 500px;
    padding: 15px;
    box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15);

    .table-header {
      font-weight: bolder;
    }

    .table-header,
    .table-row {
      display: flex;
      width: 100%;
      box-shadow: 0px 1px 1px rgba(63, 63, 68, 0.15);
      padding: 10px;

      .table-column {
        flex: 1;
        width: 33%;
      }

      .position-column {
        flex: 0;
        min-width: 75px;
      }
    }
  }
}
