.rating-overview-container {
  display: flex;
  margin: 30px 0;
  align-items: center;
  flex-direction: column;

  .rating-overview-table {
    width: 80%;
    padding: 15px;
    box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15);

    a {
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }

    .table-header {
      font-weight: bolder;
    }

    .table-header,
    .table-row {
      display: flex;
      width: 100%;
      box-shadow: 0px 1px 1px rgba(63, 63, 68, 0.15);
      padding: 10px;

    

      .table-column {
        flex: 1;
        width: 33%;
      }

      .position-column {
        flex: 0;
        min-width: 75px;
      }
    }
  }
}
