.app-container {
  display: flex;
  min-height: 100vh;

  .menu {
    flex: 0;
    box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15);
    min-width: 250px;
    background-color: #22c9fc;

    .menu-header {
      font-size: 20px;
      padding: 30px;
    }
    .menu-items {
      flex-direction: column;

      a {
        margin: 15px;
        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;
        border-bottom: solid 1px #fff;
        text-decoration: none;
      }
    }

    .menu-header,
    .menu-items,
    a {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .content {
    flex: 1;
  }
}
